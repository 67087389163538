import React from "react";
import Header from "../_components/Header";
import Button from "../_components/Button";
import Footer from "../_components/Footer";
import ImageHero from "./image1.jpg";
import "react-html5video/dist/styles.css";
import "./DescriptionPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";

class DescriptionPage extends React.Component {
  render() {
    return (
      <div className="Embassador">
        <Media query={{ minWidth: 768 }}>
          {matches => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Embassador-hero"
          style={{
            height: "350px",
            width: "100%",
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top"
          }}
        ></section>
        <section className="Embassador-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                  <FormattedMessage
                    id="page.banner"
                    defaultMessage="INSCRIPCIONES CERRADAS"
                  />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Embassador-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Embassador-title">Descripción</h3>
              </div>
            </div>
            <div className="row py-5">
              <div className="col-8 offset-2">
                <article className="text-justify">
                  <p>
                    La <strong>Pontevedra 4 Picos Desafío Rías Baixas</strong>{" "}
                    es una de las pruebas referencia a nivel nacional del BTT.
                    La prueba consta de dos opciones: por una parte, el
                    recorrido largo que sube los cuatro picos que rodean la
                    ciudad de Pontevedra, en la cual se deberán superar{" "}
                    <strong>132 km</strong> aproximadamente y un{" "}
                    <strong>desnivel acumulado positivo de 2.800mt</strong> y
                    una distancia “corta” que consta de <strong>66km</strong>{" "}
                    aproximadamente y un{" "}
                    <strong>desnivel positivo de 1600mts.</strong>
                  </p>
                  <p>
                    Durante la prueba se deberán superar los 4 Picos que rodean
                    nuestra ciudad;{" "}
                    <strong>
                      Monte Xaxán, A Fracha, O Castrove y O Acibal.
                    </strong>
                  </p>
                  <p>
                    La Pontevedra 4 Picos Bike es un gran reto personal y una de
                    las aventuras de mountain bike más excitantes y memorables.
                  </p>
                  <p>
                    Subidas, bajadas, pistas, singletracks, senderos… hacen de
                    esta 4 Picos una prueba increíble.
                  </p>
                  <p>
                    Un gran montaje de la zona de salida, un marcaje a la
                    altura, zona de exposición por parte de diferente tiendas y
                    marcas del sector, avituallamientos muy abundantes y un gran
                    avituallamiento final harán que esta prueba quede en vuestro
                    recuerdo para siempre, siendo el FINISHER la máxima que
                    pretende conseguir cualquier participante inscrito en la
                    Pontevedra 4 Picos.
                  </p>
                  <p>
                    La prueba <strong>NO es competitiva</strong>, se entiende
                    como una prueba de mejora personal, de reto particular, y es
                    por ello por lo que se realiza la medición de tiempos, que
                    ayudarán a cada persona a superarse en posteriores
                    ediciones.
                  </p>
                  <p>
                    La prueba podrá realizarse también en{" "}
                    <strong>
                      <u>PAREJAS</u>
                    </strong>
                    , o en{" "}
                    <strong>
                      <u>GRUPOS DE 3</u>
                    </strong>
                    , con la obligación de mantenerse juntos en toda la prueba
                    sin separarse más de 2 minutos de sus compañeros.
                  </p>
                </article>
              </div>
            </div>
          </div>
        </section>
        <Footer />
                <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/c/pontevedra-4-picos-bike-desafo-ras-baixas-2024"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default DescriptionPage;
