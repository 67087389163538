import React from 'react';
import { Link } from 'react-router-dom';
import LogoSvg from './logo.svg';

const Logo = () => {
  return (
    <div className="Logo">
      <div className="Logo-wrapper">
        <Link to="/">
          <img className="Logo-img" src={LogoSvg} alt="logo"/>
        </Link>
      </div>
    </div>
  )
}

export default Logo;