import React from "react";
import Header from "../_components/Header";
import Button from "../_components/Button";
import Footer from "../_components/Footer";
import styled from "styled-components";
import ImageHero from "./image1.jpg";
import "./SponsorsPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";
import sponsor2 from "./img/concello-pontevedra.svg";
import sponsor3 from "./img/depo.png";
import sponsor4 from "./img/froiz.jpg";
// import sponsor5 from "./img/nutrisport.png";
import sponsor5 from "./img/nutridos.png";
import sponsor7 from "./img/gesmagal.png";
import sponsor9 from "./img/clesa.png";
import sponsor10 from "./img/caetano.png";
//import sponsor15 from "./img/spiuk.png";
// import sponsor16 from "./img/towcar.png";
import sponsor17 from "./img/xeve.png";
import sponsor18 from "./img/xsauce.png";
//import sponsor19 from "./img/ottobosley.png";
import sponsor20 from "./img/lafuga.jpg";
import sponsor21 from "./img/mocaypascual.png";
import sponsor22 from "./img/xunta.jpeg";
//import sponsor23 from "./img/xacobeo2021.jpg";
import sponsor25 from "./img/nogal.png";
import sponsor24 from "./img/pizzeria_carlos.png";
// import sponsor25 from "./img/aguas_parano.jpg";
import sponsor26 from "./img/parasanitaria.jpg";
// import sponsor27 from "./img/sanmarcos.jpg";
import sponsor28 from "./img/dorita.png";
//import sponsor29 from "./img/artio.png";
//import sponsor30 from "./img/galifresh.jpeg";
import sponsor31 from "./img/maglia.png";

const level1 = [
  {
    name: "Concello Pontevedra",
    to: "https://www.pontevedra.gal",
    image: sponsor2
  }
];

const level01 = [
  {
    name: "Deputación Pontevedra",
    to: "https://www.depo.gal",
    image: sponsor3
  },
  {
    name: "Xunta de Galicia",
    to: "https://www.xunta.gal",
    image: sponsor22
  },
];

const level3 = [
  {
    name: "Caetano Renault",
    to: "https://www.caetanoformulagalicia.es/",
    image: sponsor10
  },
  {
    name: "Froiz",
    to: "https://www.froiz.com",
    image: sponsor4
  },
  {
    name: "Nutridos",
    to: "https://nutridos.com/es/",
    image: sponsor5
  },
  {
    name: "Pizzeria Carlos",
    to: "https://pizzeriascarlos.es/#/home",
    image: sponsor24
  },
  {
    name: "Maglia",
    to: "https://artiosport.com/",
    image: sponsor31
  },
  {
    name: "La Fuga",
    to: "https://www.lafugacycling.com/",
    image: sponsor20
  },
  {
    name: "Gesmagal",
    to: "https://www.gesmagal.com",
    image: sponsor7
  },
  {
    name: "Mocay Pascual",
    to: "https://www.pascualprofesional.com/mocay/",
    image: sponsor21
  },
  {
    name: "Dorita",
    to: "https://igmontes.com/",
    image: sponsor28
  },
  {
    name: "Xeve",
    to: "#",
    image: sponsor17
  },
  {
    name: "Nogal",
    to: "#",
    image: sponsor25
  },
  {
    name: "Clesa",
    to: "#",
    image: sponsor9
  },  
  {
    name: "Xsauce",
    to: "#",
    image: sponsor18
  },
  {
    name: "Escuela Parasanitaria",
    to: "https://escuela.parasanitaria.com/",
    image: sponsor26
  },  
];

class SponsorsPage extends React.Component {
  render() {
    return (
      <div className="Sponsors">
        <Media query={{ minWidth: 768 }}>
          {matches => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Sponsors-hero"
          style={{
            height: "350px",
            width: "100%",
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top"
          }}
        ></section>
        <section className="Sponsors-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                  <FormattedMessage
                    id="sponsors.banner"
                    defaultMessage=" Inscripciones cerradas"
                  />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Sponsors-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Sponsors-title">Sponsors</h3>
              </div>
            </div>
            <div className="row justify-content-center mt-3 mb-2">
              {level1.map(sponsor => {
                const { name, image, to } = sponsor;
                return (
                  <div className="col-12 col-sm-4 col-lg-4 mt-3 mb-5">
                    <a
                      href={to}
                      className="sponsor-wrapper p-4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img className="img-fluid" src={image} alt={name}></img>
                    </a>
                  </div>
                );
              })}
            </div>
            <div className="row justify-content-center mb-5">
              {level01.map(sponsor => {
                const { name, image, to } = sponsor;
                return (
                  <div className="col-12 col-sm-4 col-lg-4 mb-2">
                    <a
                      href={to}
                      className="sponsor-wrapper p-4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img className="img-fluid" src={image} alt={name}></img>
                    </a>
                  </div>
                );
              })}
            </div>
            <div className="row justify-content-center mt-2 mb-5">
              {level3.map(sponsor => {
                const { name, image, to } = sponsor;
                return (
                  <div className="col-12 col-sm-3 col-lg-3 mb-5">
                    <a
                      href={to}
                      className="sponsor-wrapper third-sponsor"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img className="img-fluid" src={image} alt={name}></img>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/c/pontevedra-4-picos-bike-desafo-ras-baixas-2024"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default SponsorsPage;
